import React from 'react';

const PenguinRush = () => {
    return (
        <div className='Project-Panel'>
            Penguin Rush project description
        </div>
    )
}

export default PenguinRush;