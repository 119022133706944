import React from 'react';

const NavBar = () => {
    return (
        <div className='Project-Panel'>
            The Nav Bar project description
        </div>
    )
}

export default NavBar;